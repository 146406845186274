import React from 'react';
import styled from 'styled-components';
import Procon from '@catalogo/ui-americanas-desktop-svg/icon-procon-rj.svg';

export const InfoStamps = () => (
  <Container>
    <Wrapper>
      <PaymentInfoList>
        <Text>
          <strong>Formas de pagamento: </strong>
          cartões de crédito Visa, Mastercard, Elo, American Express, Aura, Diners Club, Hipercard; boleto bancário; Ame
          Digital; Pix.
        </Text>
      </PaymentInfoList>
      <PaymentInfoList>
        <Text>
          <strong>regra de preço: </strong>
          Os preços dos produtos apresentados na loja não consideram ICMS-ST para revenda ou DIFAL para consumo. Os
          cálculos tributários são feitos na tela de pagamento da compra, podendo alterar o valor final do pedido de
          acordo com os produtos escolhidos e a região de entrega.
        </Text>
      </PaymentInfoList>
      <PaymentInfoList>
        <Text>
          <strong>compras para revenda: </strong>
          As compras para revenda precisam ser realizadas separadamente de compras com itens para Uso e Consumo.
          Selecione apenas itens com a mesma finalidade para fazer e finalizar o seu pedido.
        </Text>
      </PaymentInfoList>
    </Wrapper>

    <Stamps>
      <div id="armored_website_02">
        <param id="aw_preload" value="true" />
      </div>
      <Procon />
    </Stamps>
  </Container>
);

const Container = styled.div`
  border-top: 1px solid #cccccc;
  padding-top: 16px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
`;
const Wrapper = styled.div``;

const Text = styled.li`
  padding-bottom: 10px;
  font-size: 12px;
  strong {
    color: #35002a;
  }
`;

const PaymentInfoList = styled.ul`
  width: 65%;
  color: #666666;
`;

const Stamps = styled.div`
  align-items: center;
  justify-content: flex-end;
`;
