import React from 'react';
import styled from 'styled-components';
import { Nav } from '@catalogo/core-nav';

export const FooterItem = ({ menuItem }) => (
  <Container>
    <Link to={menuItem.link}>{menuItem.title}</Link>
  </Container>
);

const Container = styled.li`
  font: 16px Arial, sans-serif;
  font-size: 1.125em;
  text-decoration: none;
  color: #35002a;
  padding: 24px 16px;
  cursor: pointer;

  @media only screen and (min-width: 601px) {
    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &:hover {
      color: #e60014;
      text-decoration: underline;
      transition: color 0.5s;
    }
  }
`;

const Link = styled(Nav)`
  font: 16px Arial, sans-serif;
  text-decoration: none;
  color: #35002a;
  cursor: pointer;
  &:hover {
    color: #e60014;
    text-decoration: underline;
    transition: color 0.5s;
  }
`;
