import React from 'react';
import styled from 'styled-components';
import { Nav } from '@catalogo/core-nav';

export const MoreInfoOptions = ({ moreInfoList }) => (
  <Container>
    {moreInfoList.map(menuItem => (
      <Wrapper key={menuItem._id}>
        <Title>{menuItem.title.toLowerCase()}</Title>
        {menuItem.children?.map(item => (
          <Item key={item._id}>
            <Link to={item.link}>{item.title.toLowerCase()}</Link>
          </Item>
        ))}
      </Wrapper>
    ))}
  </Container>
);

const Container = styled.div`
  display: center;
  border-top: 1px solid #ccc;
  justify-content: flex-start;
  padding-bottom: 20px;
`;

const Wrapper = styled.div`
  height: 100%;
  padding: 0;
  margin: 0;
  padding-right: 40px;
  padding-bottom: 16px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Link = styled(Nav)`
  font-size: 14px;

  transition: all 0.3s ease;
  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.primaryColor};
  }
`;

const Title = styled.p`
  font-size: 16px;
  width: 100%;
  color: #35002a;
  padding: 1em 0 0 0;
  user-select: none;
`;

const Item = styled.li`
  font-size: 14px;
  color: #e9ebf4;
  padding: 1em 1.5em 0 0;
  justify-content: flex-start;

  a {
    text-decoration: none;
    color: #666;

    cursor: pointer;

    &:hover {
      color: #e60014;
      text-decoration: underline;
      transition: color 0.5s;
    }
  }
`;
