import React from 'react';
import styled from 'styled-components';
import { Animation } from '@catalogo/theme-animation';
import { useFooter } from '@catalogo/service-empresas-footer/src/hooks/footer';
import { Accordion } from '@catalogo/theme-accordion';
import { withConfig } from '@catalogo/core-connect/config';
import { FooterInfo } from './components/footer-info';
import { Header } from './components/header';

export const MoreInfoFooter = ({ visible, url, ...props }) => {
  const sitepage = `empresas/footer`;
  const { moreInfoList } = useFooter(sitepage);

  return (
    <Animation type="slide" direction="left" visible={visible}>
      <Wrapper>
        <Header url={url} />
        {moreInfoList.map(item => (
          <AccordionUI key={item.id} title={item.title} content={item.children} isLink={true} />
        ))}
        <FooterInfo />
      </Wrapper>
    </Animation>
  );
};

const Wrapper = styled.div`
  background: #e9ebf4;
  height: 100%;
  overflow: auto;
`;
const AccordionUI = styled(Accordion)`
  div {
    background-color: #e9ebf4;
  }
  ul {
    background-color: #e9ebf4;
  }
  p {
    color: #35002a;
  }
  svg {
    fill: #35002a;
  }
`;
export default withConfig(MoreInfoFooter);
