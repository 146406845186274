import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

export class Animation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  componentDidMount() {
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        this.setState({ visible: true });
      });
    });
  }

  componentDidUpdate() {
    const { visible } = this.props;
    if (this.state.visible !== visible) {
      this.setState({ visible });
    }
  }

  render() {
    const { visible } = this.state;
    const { children, type, direction, background, height, zIndex, onClick = () => {} } = this.props;

    return (
      <BackgroundOpacity show={visible && background} zIndex={zIndex}>
        <Wrapper visible={visible} effect={{ type, direction }} onClick={onClick} height={height}>
          {children}
        </Wrapper>
      </BackgroundOpacity>
    );
  }
}

Animation.propTypes = {
  children: PropTypes.object,
  type: PropTypes.string,
  direction: PropTypes.string,
  visible: PropTypes.bool,
  background: PropTypes.bool,
  onClick: PropTypes.func,
};

const BackgroundOpacity = styled.div`
  position: fixed;
  z-index: ${({ zIndex }) => (zIndex ? zIndex : '20')};
  ${({ show }) => css`
    animation: ${show ? 'fadeIn' : 'fadeOut'} 0.8s;
    ${show &&
    css`
      background: rgba(0, 0, 0, 0.4);
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    `}
    ${!show &&
    css`
      background: rgba(0, 0, 0, 0);
    `}
  `}

  @keyframes fadeIn {
    0% {
      background: rgba(0, 0, 0, 0);
    }
    100% {
      background: rgba(0, 0, 0, 0.4);
    }
  }

  @keyframes fadeOut {
    0% {
      background: rgba(0, 0, 0, 0.4);
    }
    100% {
      background: rgba(0, 0, 0, 0);
    }
  }
`;
const Wrapper = styled.div`
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  ${({ height }) => (height ? height : '100%')};
  transition: transform 0.45s cubic-bezier(0, 0, 0.3, 1);
  will-change: transform;
  ${({ effect }) => effect.type === 'slide' && effect.direction === 'left' && 'transform: translate3d(100%, 0, 0);'}
  ${({ effect }) => effect.type === 'slide' && effect.direction === 'top' && 'transform: translate3d(0, 100%, 0);'}
  ${({ effect }) => effect.type === 'slide' && effect.direction === 'right' && 'transform: translate3d(-100%, 0, 0);'}
  ${({ effect }) => effect.type === 'slide' && effect.direction === 'bottom' && 'transform: translate3d(0, -100%, 0);'}

  ${({ visible }) => visible && 'transform: translate3d(0, 0, 0);'}
`;
