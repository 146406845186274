import React from 'react';
import styled from 'styled-components';
import AempLogo from '@catalogo/ui-empresas-desktop-svg/logo-main.svg';

export const Logo = () => (
  <Container>
    <AempLogo width="192" height="20" />
  </Container>
);

const Container = styled.div`
  display: flex;
  align-items: center;
`;
