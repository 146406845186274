import React from 'react';
import styled from 'styled-components';
import { FooterItem } from './footer-item';

export const HighlightedList = ({ highlightList }) => (
  <Container>
    {highlightList.map(item => (
      <FooterItem key={item._id} menuItem={item} />
    ))}
  </Container>
);

const Container = styled.ul`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1256px;
  width: 100%;
  white-space: nowrap;
`;
