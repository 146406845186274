import React, { useState } from 'react';
import compose from 'lodash/fp/compose';
import styled from 'styled-components';
import Arrow from '@catalogo/ui-americanas-desktop-svg/arrow.svg';
import withFooter from '@catalogo/service-empresas-footer';
import { withConfig } from '@catalogo/core-connect/config';
import { Nav } from '@catalogo/core-nav';
import { Helmet } from 'react-helmet';
import { Logo } from './components/logo';
import { HighlightedList } from './components/highlighted-list';
import { MoreInfoOptions } from './components/more-info-options';
import { InfoStamps } from './components/info-stamps';
import { Address } from './components/address';

export const Footer = ({ highlightList, moreInfoList }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const infoText = isExpanded ? 'menos informações' : 'mais informações';

  return (
    <Container>
      <Helmet>
        <div id="armored_website">
          <param id="aw_preload" value="true" />
          <param id="aw_use_cdn" value="true" />
        </div>
        <script type="text/javascript" src="//cdn.siteblindado.com/aw.js" />
      </Helmet>
      <FooterLayout>
        <HighlightedList highlightList={highlightList} />
        <MoreInfoListWrapper $isExpanded={isExpanded}>
          <MoreInfoOptions moreInfoList={moreInfoList} />
          <InfoStamps />
        </MoreInfoListWrapper>
      </FooterLayout>
      <Wrapper>
        <Logo />
        <MoreInfoButton onClick={() => setIsExpanded(!isExpanded)}>
          <HiddenLink>{infoText}</HiddenLink>
          <ArrowIcon $isExpanded={isExpanded} />
        </MoreInfoButton>
      </Wrapper>
      <AddressWrapper>
        <Address />
      </AddressWrapper>
    </Container>
  );
};

export default compose(withConfig, withFooter)(Footer);

const ArrowIcon = styled(Arrow)`
  fill: #35002a;
  width: 12px;
  height: 12px;
  margin-left: 8px;
  transform: ${({ $isExpanded }) => ($isExpanded ? 'rotate(180deg)' : '')};
`;

const MoreInfoButton = styled.div`
  width: 190px;
  background: #f3f3f9;
  left: 46%;
  margin-left: -40px;
  padding: 20px;
  top: 0;
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px 0px 8px 8px;
`;

const AddressWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
`;

const Container = styled.footer`
  position: relative;
  background-color: #e9ebf4;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1256px;
  padding: 20px 0;
  margin: 0 auto;
  position: relative;
  background-color: #e9ebf4;
`;

const FooterLayout = styled.div`
  background-color: #f3f3f9;
  border-top: solid #e0e2f0 2px;
`;

const MoreInfoListWrapper = styled.div`
  max-width: 1256px;
  max-height: 350px;
  margin: 0 auto;
  overflow: hidden;
  height: ${({ $isExpanded }) => ($isExpanded ? '600px' : 0)};

  transition: height 1s ease;
`;

const HiddenLink = styled(Nav)`
  font-size: 14px;
  border-radius: 0px 0px 8px 8px;
  color: #35002a;
  transition: all 0.3s ease;
  &:hover {
    text-decoration: underline;
    color: #e60014;
  }
`;
