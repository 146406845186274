import React, { useEffect } from 'react';
import styled from 'styled-components';
import Procon from '@catalogo/ui-americanas-desktop-svg/icon-procon-rj.svg';

export const FooterInfo = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = '//selo.siteblindado.com/aw.js';

    document.head.appendChild(script);
  }, []);

  return (
    <InfoContainer>
      <Text>
        <strong>Formas de pagamento: </strong>
        cartões de crédito Visa, Mastercard, Elo, American Express, Aura, Diners Club, Hipercard; boleto bancário; Ame
        Digital; Pix.
      </Text>

      <Text>
        <strong>regra de preço: </strong>
        Os preços dos produtos apresentados na loja não consideram ICMS-ST para revenda ou DIFAL para consumo. Os
        cálculos tributários são feitos na tela de pagamento da compra, podendo alterar o valor final do pedido de
        acordo com os produtos escolhidos e a região de entrega.
      </Text>

      <Text>
        <strong>compras para revenda: </strong>
        As compras para revenda precisam ser realizadas separadamente de compras com itens para Uso e Consumo. Selecione
        apenas itens com a mesma finalidade para fazer e finalizar o seu pedido.
      </Text>

      <SealsContainer>
        <div id="armored_website_02">
          <param id="aw_preload" value="true" />
        </div>
        <Procon />
      </SealsContainer>
    </InfoContainer>
  );
};

const Text = styled.p`
  padding: 15px 0;
  font-size: 12px;
  strong {
    color: #35002a;
  }
`;

const InfoContainer = styled.div`
  padding: 20px;
`;

const SealsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
