import React from 'react';
import styled from 'styled-components';
import ArrowLeft from '@catalogo/ui-americanas-desktop-svg/arrow.svg';
import { Nav } from '@catalogo/core-nav';

export const Header = ({ url }) => (
  <Container>
    <NavUI to={url}>mais informações</NavUI>
    <BackButton to={url}>
      <BackIcon transform="rotate(90)" />
      <span>voltar</span>
    </BackButton>
  </Container>
);

const Container = styled.div``;

const NavUI = styled(Nav)`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background: #e9ebf4;
  padding: 50px 20px 20px;
  background: #e9ebf4;
  font-weight: 400;
  font-size: 22px;
  width: 100%;
  color: #35002a;
`;
const BackIcon = styled(ArrowLeft)`
  fill: #35002a;
  width: 10px;
  margin-left: 5px;
`;

const BackButton = styled(Nav)`
  display: flex;
  align-items: center;
  background: #f6f6f6;
  border: 0;
  color: #666;
  margin: 0;
  font-size: 18px;
  padding: 1em 0.5em;
  position: relative;
  text-decoration: none;
  display: flex;
  span {
    margin-left: 10px;
  }
`;
