import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import Arrow from '@catalogo/ui-americanas-desktop-svg/arrow.svg';
import { Nav } from '@catalogo/core-nav';

const getHeight = (active, contentRef) => (active ? '0px' : `${contentRef.current.scrollHeight}px`);

export const Accordion = ({ title, content, isLink = false, className, childrenContent, renderComponent }) => {
  const [active, setActiveState] = useState(false);
  const [height, setHeightState] = useState('0px');
  const contentRef = useRef(null);

  if (!title || !content) {
    return null;
  }

  const toggleAccordion = () => {
    const accordionHeight = getHeight(active, contentRef);

    setActiveState(!active);
    setHeightState(accordionHeight);
  };

  return (
    <AccordionSection className={className}>
      <AccordionHeaderWrapper onClick={toggleAccordion}>
        <Title>{title}</Title>
        <Chevron $active={active} width="10" fill="#888" />
      </AccordionHeaderWrapper>
      <AccordionContentWrapper ref={contentRef} height={height}>
        {content.map(
          item =>
            !!item.title && (
              <AccordionText key={item.id}>
                {isLink ? <AccordionLink to={item.link}>{item.title}</AccordionLink> : <Pre>{item.title}</Pre>}
              </AccordionText>
            )
        )}
        {childrenContent?.length > 0 && (
          <Wrapper>
            {childrenContent?.map(child => (
              <React.Fragment key={child._id}>{renderComponent(child)}</React.Fragment>
            ))}
          </Wrapper>
        )}
      </AccordionContentWrapper>
    </AccordionSection>
  );
};

const AccordionSection = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: -1px;
`;

const AccordionHeaderWrapper = styled.div`
  background-color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 18px;
  border: none;
  outline: none;
`;

const Title = styled.p`
  ${({ theme }) => `
    color: ${theme.grey.darker};
    font-size: ${theme.fontSize.xs};
  `};
  font-weight: 600;
  width: 100%;
  text-align: left;
  padding-right: 60px;
`;

const Chevron = styled(Arrow)`
  margin-left: auto;
  transition: transform 0.6s ease;
  transform: ${({ $active }) => ($active ? 'rotate(-180deg)' : 'none')};
`;

const AccordionContentWrapper = styled.ul`
  background-color: #fff;
  color: #888;
  transition: max-height 0.6s ease;
  overflow: hidden;
  max-height: ${({ height }) => height};
`;

const AccordionText = styled.li`
  ${({ theme }) => `font-size: ${theme.fontSize.xs};`};
  font-weight: 400;
  padding: 0 18px 18px;
  line-height: 1.5em;
`;

const AccordionLink = styled(Nav)`
  text-decoration: underline;
`;

const Pre = styled.div`
  display: block;
  background: inherit;
  border: 0;
  color: #666;
  font-family: inherit;
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;
  padding: 0;
  white-space: pre-wrap;
  word-wrap: normal;
  word-break: normal;
`;
const Wrapper = styled.div`
  padding: 0 18px 18px;
  li {
    margin-left: 20px;
  }
`;
