import React from 'react';
import styled from 'styled-components';
import { Nav } from '@catalogo/core-nav';

export const Address = () => (
  <Container>
    <AddressInfo>
      americanas s.a. / CNPJ: 00.776.574/0006-60 / Inscrição Estadual: 85.687.08-5 / Endereço Rua Sacadura Cabral, 102 -
      Rio de Janeiro, RJ - 20081-902
    </AddressInfo>

    <TalkToUsLink to="mailto:atendimento.b2b@empresas.americanas.com" title="fale com a gente">
      atendimento.b2b@empresas.americanas.com
    </TalkToUsLink>
  </Container>
);

const Container = styled.div`
  font-size: 12px;
  display: flex;
  text-align: center;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1280px;
  color: #35002a;
`;

const AddressInfo = styled.address`
  font-style: normal;
  font-size: 1em;
`;

const TalkToUsLink = styled(Nav)`
  text-decoration: none;
  padding-top: 10px;
  display: block;
  cursor: pointer;
  margin-bottom: 24px;

  &:hover {
    color: #e60014;
    text-decoration: underline;
    transition: color 0.5s;
  }
`;
